import React, { useEffect } from "react";
import { Waypoint } from "app/components/Waypoint";
import { playlistsViewScreen } from "services/typewriter/segment";
import {
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
  ANALYTICS_SCREEN_NAME,
} from "constants/analytics";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { PlaylistCard } from "app/components/PlaylistCard";
import { RouteWrapper } from "../RouteWrapper";
import { useGetSavedPlaylists } from "./hooks";
import { PlaylistListContainer } from "../styles";
import { EmptyState } from "./EmptyState";

const Playlists = () => {
  const {
    savedPlaylists,
    pageInfoSavedPlaylists,
    fetchMoreSavedPlaylists,
    loadingSavedPlaylists,
    isFetchingInitialSavedPlaylists,
  } = useGetSavedPlaylists();

  useEffect(() => {
    playlistsViewScreen({
      screen_name: ANALYTICS_SCREEN_NAME.followedPlaylists,
      location: ANALYTICS_LOCATION.playlists,
      module: ANALYTICS_MODULE.followed_playlists,
    });
  }, []);

  if (isFetchingInitialSavedPlaylists) {
    return <LoaderFullCentered />;
  }

  if (savedPlaylists.length === 0) {
    return <EmptyState />;
  }

  return (
    <PlaylistListContainer>
      {savedPlaylists &&
        savedPlaylists.map(playlist => (
          <PlaylistCard playlistData={playlist} key={playlist.id} />
        ))}
      {!loadingSavedPlaylists && (
        <Waypoint
          hasMore={pageInfoSavedPlaylists?.hasNextPage}
          fetchData={fetchMoreSavedPlaylists}
        />
      )}
    </PlaylistListContainer>
  );
};

export default () => {
  return (
    <RouteWrapper>
      <Playlists />
    </RouteWrapper>
  );
};
